import { GlobalControl } from '@/classes/stats/GlobalControl.js'
import StatsTitle from '@/components/Stats/StatsTitle/StatsTitle.vue'
import ContactsService from '@/services/contacts.service'
import GroupService from '@/services/group.service'
import CountryService from '@/services/country.service'
import DateRange from '@/components/DateRange/DateRange.vue'
import moment from 'moment'
import EventBus from '@/util/EventBus.js'
import { Contact } from '@/models/Contact.js'
import getEnv from '@/util/env'
import { ContactFilter } from '@/classes/ContactFilter.js'
import MultiSelect from '@/components/MultiSelect/MultiSelect.vue'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import { Country } from '@/util/Country.js'
import { BlacklistType } from '@/models/BlacklistType'
import { TextUtils } from '@/util/TextUtils'

export default {
  name: 'Contacts',
  components: { HeaderTopDashboard, StatsTitle, DateRange, EventBus, MultiSelect },
  data: function () {
    return {
      group: null,
      groups: [],
      dataAllGroups: [],
      dataGroupsUser: [],
      dataGroupsUserAssign: [],
      errors: [],
      dataCountry: [],
      dataCountryKeys: [],
      countrySelected: '',
      dataStoreUrls: {},
      show: false,
      globalControl: new GlobalControl(),
      loading: true,
      loadingGroup: false,
      loadingSave: false,
      options: {},
      content: [],
      searchTerm: '',
      itemsPerPage: 10,
      pageCount: 0,
      page: 1,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      selected: [],
      totalItems: 0,
      tab: null,
      editedItem: new Contact(),
      defaultItem: new Contact(),
      data: [],
      ready: true,
      startDate: undefined,
      endDate: undefined,
      selectedIds: [],
      allItemsSelected: false,
      groupSelected: null,
      newGroupName: null,
      groupId: 0,
      selectedAction: 1,
      selectedMethods: 'add_to_group',
      availableContactFields: [],
      selectedAvailableContactField: undefined,
      contactFilters: [],
      campaigns: [],
      countries: [],
      validFilters: true,
      confirmContactConsent: false,
      blacklistTypes: [
        BlacklistType.SMS_ID,
        BlacklistType.MAILING_ID,
        BlacklistType.VOICE_ID,
        BlacklistType.RCS_ID,
      ],
      Country: Country,
    }
  },
  computed: {
    headers () {
      return [
        { text: this.$t('Id'), align: 'start', value: 'id' },
        { text: 'Email', align: 'start', value: 'email' },
        { text: this.$t('Teléfono Móvil'), align: 'start', value: 'phone' },
        { text: this.$t('Teléfono Fijo'), align: 'start', value: 'landline' },
        { text: this.$t('País'), align: 'start', value: 'countryIso' },
        { text: this.$t('Fecha alta'), align: 'start', value: 'createdAt' },
        { text: this.$t('Lista negra'), align: 'start', value: 'blacklist', sortable: false },
        { text: this.$t('Fecha Lista'), align: 'start', value: 'blacklistDates', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ]
    },
    actionOptions () {
      return [
        { text: this.$t('Seleccionados').toString(), value: 1 },
        { text: this.$t('No seleccionados').toString(), value: 2 },
        { text: this.$t('Todos (Sin tener en cuenta la paginación)').toString(), value: 3 },
      ]
    },
    methods () {
      return [
        { value: 'add_to_group', text: this.$t('Añadir a grupo existente').toString() },
        { value: 'new_group', text: this.$t('Añadir a grupo nuevo').toString() },
        { value: 'add_blacklist_sms', text: this.$t('Añadir a Blacklist SMS').toString() },
        { value: 'add_blacklist_mailing', text: this.$t('Añadir a Blacklist MAILING').toString() },
        // { value: 'add_blacklist_landing', text: this.$t('Añadir a Blacklist LANDING PAGE').toString() },
        { value: 'add_blacklist_voice', text: this.$t('Añadir a Blacklist VOZ').toString() },
        { value: 'add_blacklist_rcs', text: this.$t('Añadir a Blacklist RCS').toString() },
        { value: 'add_blacklist_all', text: this.$t('Añadir a todas las Blacklist').toString() },
        { value: 'rm_blacklist_sms', text: this.$t('Eliminar de Blacklist SMS (sólo los añadidos por mí)').toString() },
        { value: 'rm_blacklist_mailing', text: this.$t('Eliminar de Blacklist MAILING (sólo los añadidos por mí)').toString() },
        // { value: 'rm_blacklist_landing', text: this.$t('Eliminar de Blacklist LANDING PAGE (sólo los añadidos por mí)').toString() },
        { value: 'rm_blacklist_voice', text: this.$t('Eliminar de Blacklist VOZ (sólo los añadidos por mí)').toString() },
        { value: 'rm_blacklist_rcs', text: this.$t('Eliminar de Blacklist RCS (sólo los añadidos por mí)').toString() },
        { value: 'rm_blacklist_all', text: this.$t('Eliminar de todas las Blacklist (sólo los añadidos por mí)').toString() },
        { value: 'rm_contact', text: this.$t('Eliminar contactos').toString() },
        { value: 12, text: this.$t('Exportar contactos').toString() },
      ]
    },
    firstFilterOptions () {
      return [
        { value: true, text: this.$t('Coincide').toString() },
        { value: false, text: this.$t('No coincide').toString() },
      ]
    },
    booleanOptions () {
      return [
        { value: 'and', text: this.$t('Y').toString() },
        { value: 'or', text: this.$t('O').toString() },
      ]
    },
    formTitle () {
      return this.editedIndex === -1 ? this.$t('Nuevo contacto') : this.$t('Editar contacto')
    },
    params (nv) {
      return {
        ...this.options,
        query: this.searchTerm,
      }
    },
    pageSelected () {
      return this.selected.length && this.selected.length < this.totalItems && !this.allItemsSelected
    },
    possibleMethods () {
      return this.methods
        .filter(m => !['new_group', 'add_to_group', 'rm_contact', 12].includes(m.value))
        .map(m => m.value)
    },
  },
  watch: {
    globalControl: {
      handler: function () {
        this.getDataFromApi()
      },
      deep: true,
    },
    params: {
      handler () {
        this.getDataFromApi()
      },
      deep: true,
    },
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
  mounted () {
    this.getGroups()
    this.getFiltersData()
    this.getCoutries()
    this.setAvailableContactFields()
    this.setGroupsData()
    this.startDate = this.globalControl.initDate
    this.endDate = this.globalControl.endDate
    EventBus.$on('changedLanguage', () => {
      this.$nextTick(() => {
        this.setAvailableContactFields()
      })
    })
  },
  methods: {
    blacklistNames (item) {
      return item.blacklistResult?.map(bl => bl.type).join('<br>')
    },
    blacklistDates (item) {
      return (Array.isArray(item.blacklistResult) && item.blacklistResult?.map(bl => bl.date).join('<br>')) || null
    },
    openContactDialog () {
      this.tab = 'contact-data'
      this.dialog = true
    },

    filterGroupsSelector (item, queryText) {
      const textOne = item.name.toLowerCase()
      const searchText = queryText.toLowerCase()
      return textOne.indexOf(searchText) > -1
    },

    setGroupsData () {
      GroupService.getUserGroups()
      .then(
        (response) => {
          this.groups = Object.assign(response)
        },
        () => { },
      )
      .finally(() => {
      })
    },

    filterCountrySelector (item, queryText) {
      const textOne = item.name.toLowerCase()
      const textTwo = item.iso_code_2.toLowerCase()
      const searchText = queryText.toLowerCase()

      return textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1
    },

    setInvalidFilters (invalid) {
      this.validFilters = !invalid
    },
    isObject (item) {
      return typeof (item) !== 'object'
    },
    remove (contactFilter) {
      contactFilter.value = null
    },
    getFiltersData () {
      ContactsService.getFiltersData().then((data) => {
        this.campaigns = data.campaigns
        this.countries = data.countries
      })
    },
    // eslint-disable-next-line complexity
    changeContactField (contactFilter) {
      contactFilter.value = null
      contactFilter.otherValue = null

      switch (contactFilter.field.type) {
        case 'date':
          contactFilter.fieldConditionOptions = this.getDateFieldContitionOptions()
          break

        case 'decimal':
          contactFilter.fieldConditionOptions = this.getNumberFieldContitionOptions()
          break

        case 'string':
          if (contactFilter.field.section === 'field' && contactFilter.field.field === 'country_name') {
            contactFilter.fieldConditionOptions = this.getCountryFieldContitionOptions()
          } else {
            contactFilter.fieldConditionOptions = this.getStringFieldContitionOptions()
          }
          break

        case 'campaign':
            contactFilter.fieldConditionOptions = this.getCampaignFieldContitionOptions()
            break

        case 'group':
            contactFilter.fieldConditionOptions = this.getGroupFieldContitionOptions()
           break
      }
    },
    getNumberFieldContitionOptions () {
      return [
        { value: 'empty', text: this.$t('Vacío').toString() },
        { value: 'not-empty', text: this.$t('No vacío').toString() },
        { value: 'greater-than', text: this.$t('Mayor que').toString() },
        { value: 'smaller-than', text: this.$t('Menor que').toString() },
        { value: 'greater-than-or-equal-to', text: this.$t('Mayor o igual a').toString() },
        { value: 'less-than-or-equal-to', text: this.$t('Menor o igual a').toString() },
        { value: 'between', text: this.$t('Entre').toString() },
      ]
    },
    getStringFieldContitionOptions () {
      return [
        { value: 'empty', text: this.$t('Vacío').toString() },
        { value: 'not-empty', text: this.$t('No vacío').toString() },
        { value: 'equal-to', text: this.$t('Igual a').toString() },
        { value: 'diff-to', text: this.$t('Distinto a').toString() },
        { value: 'start-with', text: this.$t('Empieza por').toString() },
        { value: 'ends-with', text: this.$t('Termina por').toString() },
        { value: 'contains', text: this.$t('Contiene').toString() },
      ]
    },
    getDateFieldContitionOptions () {
      return [
        { value: 'empty', text: this.$t('Vacío').toString() },
        { value: 'not-empty', text: this.$t('No vacío').toString() },
        { value: 'between', text: this.$t('Entre').toString() },
      ]
    },
    getCountryFieldContitionOptions () {
      return [
        { value: 'empty', text: this.$t('Vacío').toString() },
        { value: 'not-empty', text: this.$t('No vacío').toString() },
        { value: 'equal-to', text: this.$t('Es').toString() },
      ]
    },
    getCampaignFieldContitionOptions () {
      return [
        { value: 'empty', text: this.$t('Vacío').toString() },
        { value: 'equal-to', text: this.$t('Es').toString() },
      ]
    },
    getGroupFieldContitionOptions () {
      return [
        { value: 'empty', text: this.$t('Vacío').toString() },
        { value: 'not-empty', text: this.$t('No vacío').toString() },
        { value: 'equal-to', text: this.$t('Es').toString() },
      ]
    },
    removeFilters () {
      this.contactFilters = []
      this.getDataFromApi()
    },
    removeFilter (index) {
      this.contactFilters.splice(index, 1)
      if (this.contactFilters.length === 0) {
        this.getDataFromApi()
      }
    },
    addCondition () {
      this.contactFilters.push(new ContactFilter())
    },
    setAvailableSelectedFields () {
      this.contactFilters.forEach((filter, index) => {
        const found = this.availableContactFields.find(function (field) {
          return field.field === filter.field.field
        })
        this.contactFilters[index].field = found
      })
    },
    setAvailableContactFields () {
      this.availableContactFields = []
      this.availableContactFields.push({ text: '', disabled: true })
      ContactsService.getAvailableContactFields().then((data) => {
        data.availableFields.forEach(field => {
          this.availableContactFields.push({ divider: true })
          this.availableContactFields.push({ header: this.$t(field.groupName).toString(), disabled: true })
          this.availableContactFields.push({ divider: true })

          for (const key in field.fields) {
            this.availableContactFields.push({ field: key, text: field.fields[key].name, ...field.fields[key] })
          }
        })
        this.setAvailableSelectedFields()
      })
    },
    exportContacts () {
      const user = JSON.parse(localStorage.getItem('user'))
      const params = {
        ids: this.getSelectedIds(),
        selection: this.selectedAction,
        token: user.token,
        startDate: this.startDate,
        endDate: this.endDate,
      }

      if (this.validFilters) {
        params.filters = JSON.stringify(this.contactFilters)
      }

      const urlParams = new URLSearchParams(params).toString()
      window.open(getEnv('VUE_APP_API_URL') + 'api/contacts/export?' + urlParams, '_blank')
    },
    selectDates (dates) {
      this.startDate = dates[0]
      this.endDate = dates[1]
      this.getDataFromApi()
    },
    arrayIncludesKey (arrayList, key) {
      return Object.keys(arrayList).includes(key)
    },
    callToAction () {
      this.loading = true
      const params = {
        ids: this.getSelectedIds(),
        action: this.selectedMethods,
        groupId: this.groupSelected,
        groupName: this.newGroupName,
        selection: this.selectedAction,
        recordsDisplayTable: this.totalItems,
        startDate: this.startDate,
        endDate: this.endDate,
      }

      if (this.validFilters) {
        params.filters = JSON.stringify(this.contactFilters)
      }

      ContactsService.callToAction(params)
        .then(
          (response) => {
            if (response?.error === 0) {
              EventBus.$emit('showAlert', 'success', this.$t('Acción completada satisfactoriamente'))
            } else {
              EventBus.$emit('showAlert', 'danger', this.$t(response.message))
            }
            setTimeout(() => this.getDataFromApi(), 3000)
          },
          () => { },
        )
        .finally(() => {
          this.loading = false
        })
    },
    getSelectedIds () {
      const result = []
      this.selected.forEach(elem => {
        result.push(elem.id)
      })
      return result
    },
    selectCountry () {
      this.editedItem.countryIso = this.dataCountryKeys[this.dataCountry.indexOf(this.countrySelected)]
    },
    getCoutries () {
      CountryService.getCountries()
        .then(
          (response) => {
            this.dataCountry = Object.values(JSON.parse(JSON.stringify(response)))
            this.dataCountryKeys = Object.keys(JSON.parse(JSON.stringify(response)))
          },
          () => { },
        )
        .finally(() => {
          this.loading = false
        })
    },
    dateFormat (date) {
      return moment(String(date)).format('YYYY/MM/DD')
    },
    initialize (data) {
      const that = this
      this.content = data.map((x) => {
        return {
          id: x.id,
          email: x.email,
          phone: x.phone,
          groupIds: x.groupIds,
          landline: x.landline,
          countryIso: x.country_iso,
          name: x.name,
          surname: x.surname,
          blacklist: x.blacklist.filter(b => that.blacklistTypes.includes(b.blacklist_type.id)).map(b => TextUtils.titleCase(b.blacklist_type.name)),
          blacklistResult: x.blacklistResult,
          hasBlacklist: x.hasBlacklist,
          created_at: new Date(x.created_at),
        }
      })
    },
    applyFilters () {
      this.options.page = 1
      this.getDataFromApi()
    },
    getDataFromApi () {
      EventBus.$emit('showLoading', true)
      const params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        searchTerm: this.searchTerm,
        startDate: this.startDate,
        endDate: this.endDate,
        groupId: (this.group) ? this.group.id : null,
      }

      if (this.validFilters) {
        params.filters = this.contactFilters
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        let field = this.options.sortBy[i]
        field = field === 'createdAt' ? 'created_at' : field
        field = field === 'countryIso' ? 'country_iso' : field

        params['sortBy[' + i + '][field]'] = field
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
          ? 'asc'
          : 'desc'
      }

      this.content = []
      this.loading = true
      ContactsService.getContactDatatable(params)
        .then(
          (response) => {
            this.data = JSON.parse(JSON.stringify(response.contacts.data))
            this.initialize(response.contacts.data)
            this.itemsPerPage = parseInt(response.contacts.per_page)
            this.page = parseInt(response.contacts.current_page)
            this.totalItems = parseInt(response.contacts.total)
            if (this.allItemsSelected) {
              this.selected = [...this.data]
            }
          },
          () => { },
        )
        .finally(() => {
          this.loading = false
          EventBus.$emit('showLoading', false)
        })
    },
    editItem (item) {
      this.editedIndex = this.content.indexOf(item)
      this.confirmContactConsent = true
      this.editedItem = Object.assign({}, this.content[this.editedIndex])
      this.countrySelected = this.dataCountry[this.dataCountryKeys.indexOf(this.editedItem.countryIso)]
      this.getGroupUsers(item.id)
      this.getGroupUserAssign(item.id)
      this.openContactDialog()
    },
    openCreateContact () {
      this.getGroups(true)
      this.openContactDialog()
    },
    deleteItem (item) {
      this.editedIndex = this.content.indexOf(item)
      this.editedItem = Object.assign({}, this.content[this.editedIndex])
      this.dialogDelete = true
      this.$forceUpdate()
    },

    deleteItemConfirm () {
      this.content.splice(this.editedIndex, 1)
      this.loading = true
      ContactsService.deleteDataForm(this.editedItem)
        .then(
          (response) => {
            this.getDataFromApi()
            EventBus.$emit('showAlert', 'success', this.$t('El contacto se ha eliminado correctamente'))
          },
          (error) => {
            EventBus.$emit('showAlert', 'warning', error)
          },
        )
        .finally(() => {
          this.loading = false
        })
      this.closeDelete()
    },
    close (close = true) {
      if (!close) {
        return
      }
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.countrySelected = ''
      this.resetForm()
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    async save (close = true) {
      if (close) {
        this.loadingSave = true
      }
      try {
        const response = await ContactsService.postDataForm(this.editedItem)
        // console.log(response)
        if (response.error === 0) {
          this.editedItem.id = response.id
          EventBus.$emit('showAlert', 'success', this.$t('Contacto actualizado correctamente'))
          this.getDataFromApi()
          // eslint-disable-next-line max-depth
          if (!close) {
            this.getGroupUsers(this.editedItem.id)
            this.getGroupUserAssign(this.editedItem.id)
          }
        } else {
          EventBus.$emit('showAlert', 'danger', this.$t(response.message))
        }
      } catch (error) {
          ContactsService.errorResponse(
            error.response.status,
            this.$t('Error al actualizar el contacto'),
            this.$t(error.response.data.message),
          )
      } finally {
        if (close) {
          this.loadingSave = false
        }
        this.close(close)
      }
    },
    selectedGroups (newGroups) {
      this.editedItem.groupIds = newGroups.map(g => g.id)
    },
    getGroups (open = false) {
      GroupService.getUsersNoAnonymous().then(
        (response) => {
          this.dataAllGroups = response

          if (open) {
            this.dataGroupsUser = Object.assign(this.dataAllGroups)
            this.dataGroupsUserAssign = []
          }
        },
        () => { },
      )
      .finally(() => {
      })
    },
    getGroupUsers (contactId) {
      const params = {
        contact_id: contactId,
      }
      GroupService.getUsersNoAnonymous(params).then(
        (response) => {
          this.dataGroupsUser = response
        },
        () => { },
      )
      .finally(() => {
      })
    },
    getGroupUserAssign (contactId) {
      const params = {
        contact_id: contactId,
      }
      GroupService.getUserAssigns(params).then(
        (response) => {
          this.dataGroupsUserAssign = response
        },
        () => { },
      )
      .finally(() => {
      })
    },
    multiselectGroups: function () {
      if (typeof this.groups !== 'undefined') {
        return Object.values(this.groups)
      }
    },
    resetForm () {
      this.$refs.form.reset()
      this.resetVeeValidate()
    },
    resetVeeValidate () {
      this.$refs.observer.reset()
    },
    selectPage () {
      if (this.selected.length) {
        this.deselectAll()
      } else {
        this.selected = [...this.data]
      }
    },
    deselectOne () {
      this.allItemsSelected = false
      this.selectedAction = 1 // Seleccionados
    },
    selectAll () {
      this.allItemsSelected = true
      this.selectedAction = 3 // Todos
    },
    deselectAll () {
      this.allItemsSelected = false
      this.selectedAction = 1 // Seleccionados
      this.selected = []
    },
    async createAsignGroup (contactId) {
      this.loadingGroup = true
      await this.save(false)
      const params = {
        ids: [this.editedItem.id],
        action: 'new_group',
        groupName: this.newGroupName,
        selection: 1,
      }
      ContactsService.callToAction(params).then(
        (response) => {
          if (response.error === 0) {
            this.getDataFromApi()
            // this.getGroupUsers(this.editedItem.id)
            this.getGroupUserAssign(this.editedItem.id)
            EventBus.$emit('showAlert', 'success', this.$t('Contacto creado correctamente'))
            EventBus.$emit('showAlert', 'success', this.$t('Grupo ha sido creado correctamente'))
          } else {
            EventBus.$emit('showAlert', 'danger', this.$t(response.message))
          }
        },
        (error) => {
          ContactsService.errorResponse(
            error.response.status,
            this.$t('Error al crear el grupo'),
            this.$t('Error al actualizar el contacto'),
            this.$t(error.response.data.message),
          )
        },
      )
      .finally(() => {
        this.loadingGroup = false
      })
    },
  },
}
